import { useLayoutEffect } from 'preact/hooks';
import { useSignal } from '@preact/signals';
import { useIsTabletAndLarger } from 'signals/mediaQuery';
import SSP_ADVERT_CONSTANTS from 'components/sspAdverts/sspAdvertsConstants';
import { getAdSection } from 'components/sspAdverts/section';
import { useIsVisible } from 'components/sspAdverts/useIsVisible';
import { filters } from 'signals/filters';
import { type OfferUnion } from 'utils/isOfferType';

const elementId = 'im-bottom';

type Props = {
	mainOffer?: OfferUnion;
};

export default function BottomAd({ mainOffer }: Props) {
	const isTablet = useIsTabletAndLarger();
	const elementHeight = isTablet ? 500 : 310;
	const isVisible = useIsVisible(elementId, '600px');
	const isAdLoaded = useSignal(false);

	useLayoutEffect(() => {
		if (!isVisible.peek() || isAdLoaded.peek() || typeof globalThis.window.sssp?.getAds !== 'function') {
			return;
		}

		const zoneId = isTablet.peek()
			? SSP_ADVERT_CONSTANTS.ZONE_IDS.PATICKA
			: SSP_ADVERT_CONSTANTS.ZONE_IDS.PATICKA_MOBIL;
		const width = isTablet.peek() ? 970 : 480;
		const height = isTablet.peek() ? 310 : 480;
		globalThis.window.sssp.getAds({
			id: elementId,
			zoneId,
			width,
			height,
			options: {
				section: getAdSection(mainOffer, filters.peek()),
			},
		});
		isAdLoaded.value = true;
	}, [isVisible.value, isTablet.value]);

	useLayoutEffect(() => {
		const resetIsAdLoaded = () => {
			isAdLoaded.value = false;
		};

		globalThis.document.addEventListener('astro:after-swap', resetIsAdLoaded);

		return () => {
			globalThis.document.removeEventListener('astro:after-swap', resetIsAdLoaded);
		};
	}, []);

	return (
		<div class="mx-auto my-12 max-w-fit overflow-hidden">
			<div id={elementId} style={`{"height": ${elementHeight}`} />
		</div>
	);
}
